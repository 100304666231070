export default {
  language_picker_helper: "Taal",
  languages: {
    english: "Engels",
    german: "Duits",
    dutch: "Nederlands",
  },
  language: "Taal",
  languageForCorrespondence: "Taal voor correspondentie",
  // PatientRegister.vue
  linkExpiredText: "Registratielink verlopen! Neem dan contact op met de kliniek",
  selfRegister: "Zelf registreren",
  confirmEmailText: "Bevestig uw e-mailadres.",
  required: "Vereist.",
  invalidPassword: "Het wachtwoord moet minstens één cijfer bevatten.\n" +
      "Het wachtwoord moet minstens één kleine letter bevatten.\n" +
      "Het wachtwoord moet minstens één hoofdletter bevatten.\n" +
      "Het wachtwoord moet minstens één speciaal teken uit de opgegeven set bevatten.\n" +
      "De lengte van het wachtwoord moet tussen 8 en 32 karakters liggen.",
  passwordNotMatch: "Wachtwoord komt niet overeen",
  usernameAlreadyExists: "Gebruikersnaam bestaat al",
  invalidEmail: "Ongeldig e-mail.",
  DSGVOTitle: 'Privacybeleid',
  DSGVOText: '<p>De volgende externe leverancier levert de technische infrastructuur voor de MedITEX Survey en verwerkt de gegevens namens ons:' +
      '          <br/></br>' +
      '          CRITEX GmbH</br>' +
      '          Stobäusplatz 4</br>' +
      '          93047 Regensburg</br>' +
      '          Germany' +
      '          <br/><br/>' +
      '          De MedITEX Survey is ontwikkeld in overeenstemming met de Europese Algemene Verordening Gegevensbescherming (GDPR) en wordt voortdurend in overeenstemming hiermee geëxploiteerd. Houd er rekening mee dat het privacybeleid van de verantwoordelijke kliniek altijd is nageleefd.' +
      '          Het beschermen van uw persoonlijke gegevens is zeer belangrijk voor ons. We zijn verplicht u onder de EU General Data Protection Regulation te informeren hoe we omgaan met uw persoonlijke gegevens, meer in het bijzonder gezondheidsgegevens, in de context van gebruik t.b.v. het formulier aangaande uw medische voorgeschiedenis.' +
      '        </p>' +
      '        <h2>1. VERANTWOORDELIJKHEDEN VOOR DATA-VERWERKING</h2>' +
      '        <p>' +
      '          <br/>' +
      '           Verantwoordelijk voor de data-verwerking is:' +
      '          <br/>' +
      '          { ClinicName } hierna te noemen "centrum/kliniek".' +
      '          <br/>' +
      '          U kunt de verantwoordelijk(e) functionaris(sen) voor gegevensbescherming onder the contactgegevens op de homepage van het centrum/de kliniek vinden.' +
      '        </p>' +
      '        <h2>2. DOEL EN RECHTSGROND VAN GEGEVENSVERWERKING</h2>' +
      '        <p>' +
      '          <br/>' +
      '          Uw persoonsgegevens worden verzameld via het online anamneseformulier om de tussen u en uw arts gesloten behandelovereenkomst en de daarbij behorende verplichtingen na te komen. Hiervoor heeft de kliniek uw persoonlijke stamgegevens (zoals naam, leeftijd, woonplaats) en uw gezondheidsgegevens nodig, die u in dit online anamneseformulier kunt invullen.' +
      '          <br/>' +
      '          Het verzamelen van gezondheidsgegevens is een voorwaarde voor uw behandeling. Als de nodige informatie niet wordt verstrekt, kan een zorgvuldige behandeling door uw arts niet plaatsvinden. Als u echter niet wilt dat uw persoonlijke gegevens worden verzameld via het online medische anamneseformulier, neem dan contact op met uw arts. De wettelijke basis voor het verwerken van uw gegevens is artikel 9(2)(h) DSGVO in relatie tot sectie 22(1)(1)(b) BDSG.' +
      '        </p>' +
      '        <h2>3. ONTVANGERS VAN UW GEGEVENS</h2>' +
      '        <p>' +
      '          <br/>' +
      '          CRITEX GmbH (Stobäusplatz 4, 93051 Regensburg, info@critex.de) is een (data-)verwerker van de kliniek. In het verlengde hiervan is CRITEX GmbH verantwoordelijk voor het verstrekken van dit online medische anamneseformulier. De inhoud van het anamneseformulier en de verwerking van de persoonsgegevens is uitsluitend de verantwoordelijkheid van en wordt bepaald door het centrum/de kliniek. Het centrum/de kliniek is dus ook de ontvanger van uw gegevens.' +
      '          <br/>' +
      '          CRITEX GmbH heeft met het centrum/de kliniek een (data-)verwerkingsovereenkomst gesloten ter bescherming van uw persoonsgegevens.' +
      '          <br/>' +
      '          Binnen CRITEX GmbH hebben alleen die personen en kantoren toegang tot uw persoonsgegevens die deze nodig hebben om aan onze wettelijke en contractuele verplichtingen/verplichtingen te voldoen.' +
      '        </p>' +
      '        <h2>4. OPSLAG VAN UW GEGEVENS</h2>' +
      '        <p>' +
      '          <br/>' +
      '          Uw persoonsgegevens worden alleen bewaard zolang dit nodig is voor het centrum/de kliniek om bovengenoemde doeleinden uit te voeren. Na beëindiging van de behandelovereenkomst tussen u en het centrum/de kliniek worden uw persoonsgegevens bewaard zolang de kliniek hier wettelijk toe verplicht is. Vanwege wettelijke vereisten is de kliniek verplicht om de betreffende gegevens gedurende ten minste 10 jaar na voltooiing van de behandeling te bewaren. Volgens andere regelgeving kunnen langere bewaartermijnen van toepassing zijn, b.v. 30 jaar voor röntgenopnamen volgens artikel 28, lid 3, van de röntgenverordening.' +
      '        </p>' +
      '        <h2>5. UW RECHTEN</h2>' +
      '        <p>' +
      '          <br/>' +
      '          U heeft het recht om informatie te verkrijgen over de persoonsgegevens die op u betrekking hebben. U kunt ook vragen om onjuiste gegevens te corrigeren.' +
      '          <br/>' +
      '          Daarnaast heeft u onder bepaalde voorwaarden het recht op verwijdering van gegevens, het recht op beperking van de gegevensverwerking en het recht op gegevensoverdraagbaarheid.' +
      '          <br/>' +
      '          De verwerking van uw gegevens is gebaseerd op wettelijke voorschriften. Alleen in uitzonderlijke gevallen hebben we uw toestemming nodig. In deze gevallen heeft u het recht om uw toestemming voor toekomstige verwerking in te trekken.' +
      '          <br/>' +
      '          Bovendien heeft u het recht om een klacht in te dienen bij de bevoegde toezichthoudende autoriteit voor gegevensbescherming als u van mening bent dat de verwerking van uw persoonsgegevens niet in overeenstemming is met de GDPR.' +
      '          <br/>' +
      '          The competent supervisory authority in data protection matters is the state data protection commissioner of the federal state in which our company is based. A list of data protection officers and their contact details can be found at the following link: https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html' +
      '        </p>' +
      '        <h2>6. OVERIG</h2>' +
      '        <p>' +
      '          <br/>' +
      '          Verdere voorschriften inzake gegevensbescherming vloeien voort uit de overeenkomst(en) die u met de kliniek heeft gesloten.' +
      '          <br/>' +
      '          Als u vragen heeft of van bovengenoemde rechten gebruik wilt maken, kunt u contact opnemen met het team van het centrum/de kliniek.' +
      '        </p>' +
      '        <p>' +
      '          <br/>' +
      '          Door op de knop "Akkoord" te klikken, gaat u uitdrukkelijk akkoord met de verwerking van uw persoonlijke gegevens en de overdracht van deze gegevens aan het centrum/de kliniek.' +
      '        </p>',
  userName : "gebruikersnaam",
  lastName : "Achternaam",
  firstName : "Voornaam",
  password: "Wachtwoord",
  repeatPassword: "herhaal wachtwoord",
  register: "register",
  createAccount: "Account aanmaken",
  agb: "AGB",
  dsgvo_1: "Ik heb het ",
  dsgvo_2: "privacybeleid",
  dsgvo_3: " gelezen.",
  close: "Dichtbij",
  dsgvoReminder: 'Accepteer onze privacyvoorwaarden om door te gaan.',

  registrationConfirmation: 'Registratie Bevestiging',
  registrationConfirmed: 'Inschrijving bevestigd.',
  registrationConfirmationExpired: 'Registratie bevestigingslink verlopen.',
  login: 'Log-In',
  backToLoginFromRegister: 'Al een account? Inloggen',
  email: 'E-mail',
  cancel: 'Annuleren',
  create: 'Creëren',
  agree: 'Mee eens',
  disagree: 'Het oneens zijn',

  patients: 'Patiënten',
  openSurveys: 'Enquêtes openen',
  chooseSurvey: 'Selecteer enquête',
  noDataAvailable: 'Er konden geen enquêtes worden gevonden. Houd er rekening mee dat een enquête maar één keer kan worden ingevuld.',
  deletePartner: 'Partner verwijderen',
  disconnectPartner: 'Partner verbreken',
  addPartner: 'Partner toevoegen',
  name: 'Naam',
  forename: 'Voornaam',
  birthdate: "Verjaardag",
  surveyName: 'Enquêtenaam',
  status: 'Toestand',
  actions: 'Acties',
  deletePartnerTitle: 'Partner verwijderen',
  deletePartnerText: 'Wil je echt je partner verwijderen?',
  disconnectPartnerTitle: 'Partner verbreken',
  disconnectPartnerText: 'Wil je echt de verbinding met je partner verbreken? Dit is niet omkeerbaar!',
  surveySentToClinic: 'Enquête verzonden naar kliniek',
  surveySentBackToEdit: 'Enquête teruggestuurd om twe bewerken',
  save: 'Opslaan',
  surveyNext: "Volgende",
  surveyPrev: "Vorige",
  sendSurvey: 'Enquête naar kliniek sturen',
  surveys: 'Enquêtes',
  deleteSurveyTitle: 'Enquête verwijderen',
  deleteSurveyText: 'Wilt u deze enquête echt verwijderen? Dit is niet omkeerbaar!',
  savedCurrentState: 'Huidige staat opslaan',
  greeting: 'Hallo',
  logout: 'Uitloggen',
  addSurvey: "Enquête toevoegen",
  help: "Helpen",
  helpText: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
  patientTutorial: "<div><p>1. Aan de linkerzijde heeft u twee opties: \"Persoonlijke gegevens\" en \"Medische geschiedenis\". Klik op <strong>\"Persoonlijke gegevens\"</strong> om je stamgegevens in te vullen en op <strong>\"Medische geschiedenis\"</strong> om je medische geschiedenis in te vullen.</p><p>2. Uw naam is reeds aangemaakt. Klik nu op <strong>\"Enqu&ecirc;te toevoegen\"</strong>.</p><p>3. Hier verschijnt uw <strong>Vragenlijsten-Overzicht</strong>. Kies een vragenlijst uit, indien u <strong>\"Enqu&ecirc;te toevoegen\"</strong> klikt. Kies een van de mogelijke vragenlijsten uit. <strong><br /></strong></p><p>4. Vul de vragenlijst in.</p><p>5. Indien u de vragenlijst op een later tijdstip verder in wenst te vullen, klikt u op de button <strong>\"Opslaan\"</strong>, welke zich aan de rechterzijde bevindt.</p><p>6. Zodra u de vragenlijst volledig heeft ingevuld, kunt u aan de rechterzijde op de button <strong>\"Vragenlijst aan kliniek sturen\"</strong> klikken. Als je kliniek een vragenlijst met basisgegevens aanbiedt, moet deze eerst worden opgestuurd en kan daarna de medische geschiedenis worden opgestuurd.</p><p>7. Om uw vragenlijst opnieuw te laden, klikt u aan de linkerzijde \"Persoonlijke gegevens\" of \"Medische geschiedenis\" aan. Daar kunt u alle ingevulde vragenlijsten inzien.</p><p>8. Een partner kan via de optie \"Pati&euml;nt\" toegevoegd worden. Deze functie vindt u aan de rechter zijde onder uw profielfunctie. Uw partner kan de voorafgaande stappen dan ook doorlopen.</p></div>",
  imprint: 'afdruk',
  typeMessage: "Typ een bericht",
  patientConversation:"Patiënten gesprek",
  resendMail: "Mail opnieuw verzenden",

  noResultsFound: "Geen resultaten gevonden",
  surveysPerSite: "Enquêtes per locatie",

  //patientProfile
  myAccount: "Mijn account",
  editAccount: "Account bewerken",
  myPartner: "Mijn partner",
  editPartner: "Partner bewerken",
  changePassword: "Wachtwoord wijzigen",
  currentPassword: "Huidig wachtwoord",
  newPassword: "Nieuw wachtwoord",
  repeatNewPassword: "Herhaal nieuw wachtwoord",

  //passwordReset
  forgotPassword: "Wachtwoord vergeten?",
  forgotPasswordText: '<div>Vul uw gebruikersnaam en herstelsleutel in. U ontvangt een nieuwe herstelsleutel bij uw volgende succesvolle login.</br></br><strong>Als u uw account vóór juli 2024 heeft aangemaakt, heeft u geen herstelsleutel. Om veiligheidsredenen kunnen we geen wachtwoordreset via e-mail meer aanbieden. Neem contact op met uw kliniek en/of maak een nieuw gebruikersaccount aan.</strong></div>',
  sentResetMail: "Er is een e-mail naar u verzonden. Klik op de link in de mail om uw wachtwoord opnieuw in te stellen.",
  sendEmail: "Stuur mail",
  backToLogin: "Terug naar login",
  resetPasswordText: "Voer een nieuw wachtwoord in",
  linkExpired: "Deze link is verlopen",
  resetPassword: "Wachtwoord resetten",
  logoutAfterPasswordChange: "Je wordt uitgelogd nadat je je wachtwoord hebt gewijzigd.",
  recoveryCode:"herstelsleutel",
  confirm: "Bevestigen",

  //snackbar
  unexpectedError: 'Er is een onverwachte fout opgetreden',
  errorCreatingPartner: "Kan partner niet maken",
  errorConfirmingRegistration: "Kan registratie niet bevestigen",
  invalidLogin: "Verkeerde gebruikersnaam of wachtwoord!",
  errorDeletingPartner: "Kan partner niet verwijderen",
  errorDisconnectingPartner: "Kan partner niet loskoppelen",
  errorFinishingRegistration: "Kan de registratie niet voltooien",
  errorRegisteringPatient: "Kan patiënt niet registreren",
  mailResent: "E-mail opnieuw verzenden",
  errorResendingMail: "Kan e-mail niet opnieuw verzenden",
  errorDeletingSurvey: "Kan enquête niet verwijderen",
  surveyResultSaved: "Enquête opgeslagen",
  errorSavingSurveyResult: "Kan enquête niet opslaan",
  surveyResultSendToClinic: "Enquêteresultaat verzonden naar kliniek",
  errorGettingPatientInformation: "Kon geen patiëntinformatie krijgen",
  patientUpdated: "Patiënt bijgewerkt",
  errorUpdatingPatient: "Kon patiënt niet bijwerken",
  partnerUpdated: "Partner bijgewerkt",
  errorUpdatingPartner: "Kon partner niet bijwerken",
  passwordChanged: "Wachtwoord gewijzigd",
  errorChangingPassword: "Kon wachtwoord niet wijzigen",
  errorSendingResetMail: "Kan geen mail versturen",
  errorResettingPassword: "Kan wachtwoord niet resetten",
  messageDeleted: "Bericht verwijderd",
  errorEstablishingRecoveryKey: "Er is een fout opgetreden bij het maken van de herstelcode.",
  successEstablishingRecoveryKey: "Herstelcode aangemaakt.",

  //cookies
  cookiesText: "<div><p>De cookies die op deze website zijn opgeslagen dienen alleen het login proces. Toestemming is <strong>vereist</strong> voor gebruik.</p></div>",
  cookieLabel: "Inloggen",
  result: "Resultaat",
  openDemographics: "Open masterdata",
  demographicsNotAvailable: "Er bestaat nog geen master data enquête voor uw kliniek",
  information: "Informatie",
  invalidEMail: "Ongeldig e-mail.",
  gender: "Geslacht",
  female: "vrouwelijk",
  male: "mannelijk",
  diverse: "divers",
  dateOfCreation: "Datum oprichting",
  surveyInProgress: "In uitvoering",
  notPossible: "Niet mogelijk",
  fillOut: "Opslaan + enquête invullen",
  warningDemographicsFirst: "Stuur de enquête met basisgegevens eerst naar de kliniek.",
  leaveWithoutSaving: "Het verlaten van deze pagina zal alle niet-opgeslagen wijzigingen negeren. Weet u zeker dat u wilt doorgaan?",
  personalData:"Persoonlijke gegevens",
  medicalHistory:"Medische geschiedenis",
  surveyNotActivated: "Medische geschiedenis is niet actief.",

  recoveryOne: "Maak een herstelmethode aan om ervoor te zorgen dat je altijd toegang hebt tot je account. Dit kan je toegang tot je account verschaffen in geval van een verloren of vergeten wachtwoord.",
  recoveryTwo: "Klik op de knop om een herstelmethode in te stellen.",
  recoveryThree: "Dit is je herstelsleutel. Kopieer deze en bewaar hem op een veilige plaats.",
  recoveryFour: "Voer je wachtwoord in om de ontvangst van de herstelsleutel te bevestigen.",
  recoveryMethod: "Herstelmethode creëren",
  recovery: "Herstel"
};
