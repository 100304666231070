const  bufferInstance = require("buffer/").Buffer;

module.exports = {
    writeToStorage(key, value) {
        const valueBuffer = bufferInstance.from(value);
        const keyBuffer = bufferInstance.from(key);

        localStorage.setItem(keyBuffer.toString('base64'), valueBuffer.toString('base64'));
    },
    readFromStorage(key) {
        const keyBuffer = bufferInstance.from(key);
        const encodedValue = localStorage.getItem(keyBuffer.toString('base64'));
        if (encodedValue) {
            const decodedValue = bufferInstance.from(encodedValue, 'base64');
            return decodedValue.toString('ascii');
        }

        return null;
    },
};