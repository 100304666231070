/* eslint-disable semi */
import en from './locales/en';
import de from './locales/de';
import nl from './locales/nl';
import it from './locales/it';

export default {
  en,
  de,
  nl,
  it
};
