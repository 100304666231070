export default {
  language_picker_helper: "Sprache",
  languages: {
    english: "Englisch",
    german: "Deutsch",
    dutch: "Niederländisch",
    italian: "Italienisch"
  },
  language: "Sprache",
  languageForCorrespondence: "Sprache zur Korrespondenz",
  // PatientRegister.vue
  linkExpiredText: "Registrierungslink abgelaufen! Bitte wenden Sie sich an die Klinik",
  selfRegister: "Selbstregistrierung",
  confirmEmailText: "Bitte bestätigen Sie Ihre E-Mail-Adresse.",
  required: "Erforderlich.",
  invalidPassword: "Das Passwort muss mindestens eine Ziffer enthalten.\n" +
      "Das Passwort muss mindestens einen Kleinbuchstaben enthalten.\n" +
      "Das Passwort muss mindestens einen Großbuchstaben enthalten.\n" +
      "Das Passwort muss mindestens ein Sonderzeichen aus der angegebenen Menge enthalten.\n" +
      "Die Länge des Passworts muss zwischen 8 und 32 Zeichen liegen",
  passwordNotMatch: "Passwort stimmt nicht überein",
  usernameAlreadyExists: "Benutzername existiert bereits",
  invalidEmail: "Ungültige E-Mail.",
  DSGVOTitle: 'Datenschutzinformationen',
  DSGVOText: ' <p>Der folgende Drittanbieter stellt die technische Infrastruktur für die MedITEX Survey bereit und verarbeitet die Daten in unserem Auftrag: ' +
      '          <br/></br>' +
      '          CRITEX GmbH</br>' +
      '          Stobäusplatz 4</br>' +
      '          93047 Regensburg</br>' +
      '          Germany' +
      '          <br/><br/>' +
      '          Die MedITEX Survey wurde in Übereinstimmung mit der europäischen Datenschutzgrundverordnung (GDPR) entwickelt und wird kontinuierlich in Übereinstimmung mit dieser betrieben. Bitte beachten Sie, dass die Datenschutzbestimmungen der verantwortlichen Klinik stets beachtet wurden.' +
      '          Wir sind nach der EU-Datenschutzgrundverordnung verpflichtet, Sie über die Verarbeitung Ihrer personenbezogenen Daten, insbesondere Gesundheitsdaten, im Rahmen der Nutzung des Online-Anamnesebogens zu informieren.' +
      '        </p>' +
      '        <h2>1. VERANTWORTLICHKEIT FÜR DIE DATENVERARBEITUNG</h2>' +
      '        <p>' +
      '          <br/>' +
      '          Verantwortlich für die Datenverarbeitung ist:' +
      '          <br/>' +
      '          { ClinicName } nachfolgend „Klinik“ genannt.' +
      '          <br/>' +
      '          Sie können den zuständigen Datenschutzbeauftragte/n unter den Kontaktdaten auf der Homepage der Klinik erreichen.' +
      '        </p>' +
      '        <h2>2. ZWECK UND RECHTSGRUNDLAGE DER DATENVERARBEITUNG</h2>' +
      '        <p>' +
      '          <br/>' +
      '          Die Erfassung Ihrer personenbezogenen Daten über den Online-Anamnesebogen erfolgt, um den zwischen Ihnen und Ihrem Arzt geschlossenen Behandlungsvertrag und die damit verbundenen Pflichten zu erfüllen. Hierfür benötigt die Klinik Ihre Personenstammdaten (wie Namen, Alter, Wohnort) sowie Ihre Gesundheitsdaten, die Sie in diesem Online-Anamnesebogen eintragen können.' +
      '          <br/>' +
      '          Die Erhebung von Gesundheitsdaten ist die Voraussetzung für Ihre Behandlung. Werden die notwendigen Informationen nicht bereitgestellt, kann eine sorgfältige Behandlung seitens Ihres Arztes nicht erfolgen. Sollten Sie jedoch eine Erfassung Ihrer personenbezogenen Daten über den Online-Anamnesebogen nicht wünschen, wenden Sie sich bitte an Ihren Arzt. Rechtsgrundlage für die Verarbeitung Ihrer Daten ist Artikel 9 Absatz 2 lit. h) DSGVO in Verbindung mit Paragraf 22 Absatz 1 Nr. 1 lit. b) BDSG.' +
      '        </p>' +
      '        <h2>3. EMPFÄNGER IHRER DATEN</h2>' +
      '        <p>' +
      '          <br/>' +
      '          Die CRITEX GmbH (Stobäusplatz 4, 93051 Regensburg, info@critex.de) ist Auftragsverarbeiter der Klinik. Im Zuge dessen ist die CRITEX GmbH für die Bereitstellung dieses Online-Anamnesebogens verantwortlich. Den Inhalt des Anamnesebogen und die Verarbeitung der personenbezogenen Daten übernimmt und bestimmt allein die Klinik. Somit ist auch die Klinik der Empfänger Ihrer Daten.' +
      '          <br/>' +
      '          Die CRITEX GmbH hat mit der Klinik einen Auftragsverarbeitungsvertrag geschlossen, um Ihre personenbezogenen Daten zu schützen.' +
      '          <br/>' +
      '          Innerhalb der CRITEX GmbH erhalten nur die Personen und Stellen Zugriff auf Ihre personenbezogenen Daten, die diese zur Erfüllung unserer gesetzlichen und vertraglichen Verpflichtungen/Pflichten benötigen.' +
      '        </p>' +
      '        <h2>4. SPEICHERUNG IHRER DATEN</h2>' +
      '        <p>' +
      '          <br/>' +
      '          Ihre personenbezogenen Daten werden nur solange aufbewahrt, wie dies für die Durchführung der oben genannten Zwecke für die Klinik erforderlich ist. Nach Beendigung des Behandlungsvertrages zwischen Ihnen und der Klinik werden Ihre personenbezogenen Daten solange gespeichert, wie die Klinik gesetzlich dazu verpflichtet ist. Aufgrund rechtlicher Vorgaben ist die Klinik dazu verpflichtet, entsprechende Daten mindestens 10 Jahre nach Abschluss der Behandlung aufzubewahren. Nach anderen Vorschriften können sich längere Aufbewahrungsfristen ergeben, so z.B. 30 Jahre bei Röntgenaufzeichnungen laut § 28 Abs. 3 der Röntgenverordnung.' +
      '        </p>' +
      '        <h2>5. IHRE RECHTE </h2>' +
      '        <p>' +
      '          <br/>' +
      '          Sie haben das Recht, über die Sie betreffenden personenbezogenen Daten Auskunft zu erhalten. Auch können Sie die Berichtigung unrichtiger Daten verlangen.' +
      '          <br/>' +
      '          Darüber hinaus steht Ihnen unter bestimmten Voraussetzungen das Recht auf Löschung von Daten, das Recht auf Einschränkung der Datenverarbeitung, sowie das Recht auf Datenübertragbarkeit zu.' +
      '          <br/>' +
      '          Die Verarbeitung Ihrer Daten erfolgt auf Basis von gesetzlichen Regelungen. Nur in Ausnahmefällen benötigen wir Ihr Einverständnis. In diesen Fällen haben Sie das Recht, die Einwilligung für die zukünftige Verarbeitung zu widerrufen.' +
      '          <br/>' +
      '          Ferner haben Sie das Recht, sich bei der zuständigen Aufsichtsbehörde für Datenschutz zu beschweren, sollten Sie der Ansicht sein, dass die Verarbeitung Ihrer personenbezogenen Daten nicht im Einklang mit der DSGVO geschieht.' +
      '          <br/>' +
      '          Zuständige Aufsichtsbehörde in datenschutzrechtlichen Fragen ist der Landesdatenschutzbeauftragte des Bundeslandes, in dem unser Unternehmen seinen Sitz hat. Eine Liste der Datenschutzbeauftragten sowie deren Kontaktdaten können folgendem Link entnommen werden: https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html.' +
      '        </p>' +
      '        <h2>6. SONSTIGES</h2>' +
      '        <p>' +
      '          <br/>' +
      '          Weitere Datenschutzbestimmungen ergeben sich aus dem von Ihnen mit der Klinik geschlossenen Verträgen.' +
      '          <br/>' +
      '          Sollten Sie Fragen haben oder die genannten Rechte ausüben wollen, können Sie sich gerne an das Team der Klinik wenden.' +
      '        </p>' +
      '        <p>' +
      '          <br/>' +
      '          Durch klicken auf den Button „Einwilligen“ erklären Sie sich mit der Verarbeitung Ihrer personenbezogenen Daten und die Übermittlung dieser an die Klinik ausdrücklich einverstanden.' +
      '        </p>',
  userName : "Benutzername",
  lastName : "Nachname",
  firstName : "Vorname",
  birthdate: "Geburtstag",
  password: "Passwort",
  repeatPassword: "Passwort wiederholen",
  register: "Registrieren",
  createAccount: "Account erstellen",
  agb: "AGB",
  dsgvo_1: 'Ich habe die ',
  dsgvo_2: 'Datenschutzinformationen',
  dsgvo_3: ' gelesen.',
  close: 'Schließen',
  dsgvoReminder: 'Bitte stimmen Sie unseren Datenschutzbestimmungen zu, um fortzufahren.',

  registrationConfirmation: 'Registrierungsbestätigung',
  registrationConfirmed: 'Registrierung bestätigt.',
  registrationConfirmationExpired: 'Registrierungslink abgelaufen.',
  login: 'Anmelden',
  backToLoginFromRegister: 'Bereits registriert? Anmelden',
  email: 'Email',
  cancel: 'Abbrechen',
  create: 'Erstellen',
  agree: 'Zustimmen',
  disagree: 'Nicht zustimmen',

  patients: 'Patienten',
  openSurveys: 'Fragebögen öffnen',
  chooseSurvey: 'Bitte wählen Sie Fragebogen aus',
  noDataAvailable: 'Es konnten keine Fragebögen gefunden werden. Bitte beachten Sie, dass ein Fragebogen nur einmal ausgefüllt werden kann.',
  deletePartner: 'Partner*in löschen',
  disconnectPartner: 'Partner*in trennen',
  addPartner: 'Partner*in hinzufügen',
  name: 'Nachname',
  forename: 'Vorname',
  surveyName: 'Fragebogentitel',
  status: 'Status',
  actions: 'Aktionen',
  deletePartnerTitle: 'Partner*in löschen',
  deletePartnerText: 'Wollen Sie wirklich Ihren Partner löschen?',
  disconnectPartnerTitle: 'Partner*in trennen',
  disconnectPartnerText: 'Wollen Sie wirklich Ihren Partner trennen? Dieser Vorgang ist nicht umkehrbar!',
  surveySentToClinic: 'Fragebogen wurde an Klinik gesendet',
  surveySentBackToEdit: 'Fragebogen wurde zur Überarbeitung zurück gesendet',
  save: 'Speichern',
  sendSurvey: 'Fragebogen an Klinik senden',
  surveys: 'Fragebögen',
  deleteSurveyTitle: 'Fragebogen löschen',
  deleteSurveyText: 'Wollen Sie wirklich diesen Fragebogen löschen? Dieser Vorgang ist nicht umkehrbar!',
  savedCurrentState: 'aktueller Stand wurde gespeichert',
  greeting: 'Hallo',
  logout: 'Abmelden',
  addSurvey: "Fragebogen hinzufügen",
  help: "Hilfe",
  helpText: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
  patientTutorial: '<div><ol type="1"><li>Klicken Sie auf <strong>Pers&ouml;nliche Daten</strong>, um Ihre Kontakt- und Stammdaten auszufüllen. Wenn Ihre Kliniken die Persönlichen Daten nicht verwendet, können Sie direkt zum Abschnitt <strong>Anamnese</strong> springen.</li>' +
      '<li>Bitte klicken Sie auf <strong>+FRAGEBOGEN HINZUF&Uuml;GEN</strong> und wählen Sie dann einen Fragebogen aus.</li>' +
      '<li>Wenn Sie den Fragebogen zu einem sp&auml;teren Zeitpunkt weiter ausf&uuml;llen m&ouml;chten, klicken Sie auf die Schaltfl&auml;che <strong>Speichern</strong>, welche sich auf der rechten Seite befindet.</li>' +
      '<li>Wenn Sie den Fragebogen vollst&auml;ndig ausgef&uuml;llt haben, klicken Sie auf die Schaltfl&auml;che auf der rechten Seite: <strong>Fragebogen an Klinik senden</strong>.</li>' +
      '<li>Ein*e Partner*in kann auf der Seite Pers&ouml;nliche Daten hinzugefügt werden. Bitte klicken Sie auf <strong>+PARTNER*IN HINZUF&Uuml;GEN</strong>, anschlie&szlig;end kann der gleiche Prozess für die pers&ouml;nlichen Daten durchlaufen werden.</li></ol><br>'+
      '<p>Anamnese</p>' +
      '<ol type="1"><li>Klicken Sie auf <strong>Anamnese</strong>, um ihre medizinischen Vorgeschichte anzugeben.</li>' +
      '<li>Bitte klicken Sie auf <strong>+FRAGEBOGEN HINZUF&Uuml;GEN</strong> und wählen Sie dann einen Fragebogen aus.</li>' +
      '<li>Wenn Sie den Fragebogen zu einem sp&auml;teren Zeitpunkt weiter ausf&uuml;llen m&ouml;chten, klicken Sie auf die Schaltfl&auml;che <strong>Speichern</strong>, welche sich auf der rechten Seite befindet.</li>' +
      '<li>Wenn Sie den Fragebogen vollst&auml;ndig ausgef&uuml;llt haben, klicken Sie auf die Schaltfl&auml;che auf der rechten Seite <strong>Fragebogen an Klinik senden</strong>.</li>' +
      '<li>Ein*e Partner*in kann auf der Seite Pers&ouml;nliche Daten hinzugefügt werden. Wenn Sie noch keine Partner*in hinzugef&uuml;gt haben, klicken Sie bitte auf <strong>+PARTNER*IN HINZUF&Uuml;GEN</strong>, anschlie&szlig;end kann der gleiche Prozess für die medizinische Vorgeschichte durchlaufen werden.</li></ol></div>',
  imprint: 'Impressum',
  typeMessage: "Nachricht eingeben",
  patientConversation: "Patientgespräch",
  resendMail: "Email erneut senden",

  noResultsFound: "Keine Ergebnisse gefunden",
  surveysPerSite: "Fragebögen pro Seite",

  //patientProfile
  myAccount: "Mein Account",
  editAccount: "Account bearbeiten",
  myPartner: "Mein Partner",
  editPartner: "Partner bearbeiten",
  changePassword: "Passwort ändern",
  currentPassword: "aktuelles Passwort",
  newPassword: "neues Passwort",
  repeatNewPassword: "neues Passwort wiederholen",

  //decisionTree
  decisionTreeStartText: 'Bitte füllen Sie diese Umfrage nach besten Gewissen aus. Falls wir den von Ihnen gesuchten Dienst anbieten können, besteht am Ende die Möglichkeit einen Termin zu beantragen.',
  phoneNumber: 'Telefonnummer',
  appointmentRequest: 'Terminanfrage',
  requestAppointment: 'Termin anfragen',
  back: 'zurück',
  next: 'weiter',
  start: 'starten',
  surveyNext: "weiter",
  surveyPrev: "zurück",
  phoneNumberRule: "Ungültige Telefonnummer! Nur 0-9 und '-' benutzen",
  nothingSelectedError: "Nichts ausgewählt!",
  appointmentRequestedText: "Danke, dass Sie Ihre Kontaktdaten eingegeben haben. Sie sollten zusätzlich eine Bestätigungsmail erhalten. Wir melden uns in Kürze bei Ihnen.",

  //passwordReset
  forgotPassword: "Passwort vergessen?",
  forgotPasswordText: '<div>Bitte geben Sie Ihren Benutzernamen und den Wiederherstellungsschlüssel ein. Beim nächsten erfolgreichen Login erhalten Sie einen neuen Wiederherstellungsschlüssel.</br></br><strong>Sollten Sie Ihren Account vor Juli 2024 erstellt haben, besitzen Sie keinen Wiederherstellungsschlüssel. Aus Sicherheitsgründen können wir keinen Passwortreset per E-Mail mehr anbieten. Bitte wenden Sie sich an Ihre Klinik und/oder erstellen Sie einen neuen Benutzeraccount.</strong></div>',
  sentResetMail: "Eine Email wurde an Sie gesendet. Bitte klicken Sie auf den Link in der Email, um Ihr Passwort zurück zusetzen",
  sendEmail: "Email senden",
  backToLogin: "Zurück zum Login",
  resetPasswordText: "Bitte geben Sie ein neues Passwort ein",
  linkExpired: "Dieser Link ist abgelaufen",
  resetPassword: "Passwort zurücksetzten",
  logoutAfterPasswordChange: "Sie werden nach dem Ändern Ihres Passworts abgemeldet.",
  recoveryCode:"Wiederherstellungsschlüssel",
  confirm: "Bestätigen",

  //snackbar
  unexpectedError: 'Ein unerwarteter Fehler ist aufgetreten',
  errorCreatingPartner: "Partner konnte nicht erstellt werden",
  errorConfirmingRegistration: "Anmeldung konnte nicht bestätigt werden",
  invalidLogin: "Falscher Benutzername oder falsches Passwort!",
  errorDeletingPartner: "Partner konnte nicht gelöscht werden",
  errorDisconnectingPartner: "Verbindung zum Partner konnte nicht getrennt werden",
  errorFinishingRegistration: "Die Registrierung konnte nicht abgeschlossen werden",
  errorRegisteringPatient: "Patient konnte nicht registriert werden",
  mailResent: "E-Mail erneut gesendet",
  errorResendingMail: "E-Mail konnte nicht erneut gesendet werden",
  errorDeletingSurvey: "Fragebogen konnte nicht gelöscht werden",
  surveyResultSaved: "Fragebogen gespeichert",
  errorSavingSurveyResult: "Fragebogen konnte nicht gespeichert werden",
  surveyResultSendToClinic: "Fragebogen wurde an die Klinik gesendet",
  errorGettingPatientInformation: "Patienten Informationen konnten nicht geladen werden",
  patientUpdated: "Patient bearbeitet",
  errorUpdatingPatient: "Patient konnte nicht bearbeitet werden",
  partnerUpdated: "Partner bearbeitet",
  errorUpdatingPartner: "Partner konnte nicht bearbeitet werden",
  passwordChanged: "Passwort wurde geändert",
  errorChangingPassword: "Passwort konnten nicht geändert werden",
  errorSendingResetMail: "Email konnte nicht gesendet werden",
  errorResettingPassword: "Passwort konnte nicht zurückgesetzt werden",
  messageDeleted: "Nachricht gelöscht",
  errorEstablishingRecoveryKey: "Ein Fehler beim erstellen des Wiederherstellungsschlüssels ist aufgetreten.",
  successEstablishingRecoveryKey: "Wiederherstellungsschlüssel erstellt.",

  //cookies
  cookiesText: "<div><p>Die Cookies, die auf dieser Website gespeichert werden, sind ausschließlich für den Anmelde-Prozess. Die Zustimmung ist <strong>erforderlich</strong> für die Nutzung.</p></div>",
  cookieLabel: "Anmeldung",
  result: "Ergebnis",
  openDemographics: "Stammdaten öffnen",
  demographicsNotAvailable: "Es gibt noch keinen Stammdaten Fragebogen für Ihre Klinik.",
  information: "Information",
  invalidEMail: "Ungültige E-Mail.",
  gender: "Geschlecht",
  female: "weiblich",
  male: "männlich",
  diverse: "divers",
  dateOfCreation: "Erstellungsdatum",
  surveyInProgress: "In Bearbeitung",
  notPossible: "Nicht möglich",
  fillOut: "Speichern + Stammdaten ausfüllen",
  warningDemographicsFirst: "Bitte zuerst den Stammdaten-Fragebogen an die Klinik schicken.",
  leaveWithoutSaving: "Das Verlassen dieser Seite verwirft alle ungespeicherten Änderungen. Möchten Sie wirklich fortfahren?",
  personalData:"Persönliche Daten",
  medicalHistory: "Anamnese",
  surveyNotActivated: "Das Anamnese Feature ist nicht aktiv.",

  recoveryOne: "Bitte erstellen Sie einen Wiederherstellungsschlüssel, um sicherzustellen, dass Sie immer Zugriff auf Ihren Account haben. Diese kann Ihnen im Falle eines verlorenen oder vergessenen Passworts Zugriff auf Ihr Konto ermöglichen.",
  recoveryTwo : "Klicken Sie auf den Button, um den Wiederherstellungsschlüssel einzurichten",
  recoveryThree: "Das ist Ihr Wiederherstellungsschlüssel. Bitte kopieren sie diesen und speichern Sie ihn an einem sicheren Ort",
  recoveryFour: "Bitte geben Sie ihr Passwort ein, um den Empfang des Wiederherstellungsschlüssels zu bestätigen",
  recoveryMethod : "Wiederherstellungsschlüssel erstellen",
  recovery: "Wiederherstellung",
};
