/* eslint-disable semi */
import Vue from 'vue';
import VueLocalStorage from 'vue-localstorage';
import Languages from '../../languages/translations';

Vue.use(VueLocalStorage);
const supportedLanguages = Object.getOwnPropertyNames(Languages);

export default {
  namespaced: true,
  state: {
    language: Vue.localStorage.get('language')
  },
  mutations: {
    SET_LANGUAGE(state, lang) {
      try {
        Vue.localStorage.set('language', lang);
        state.language = lang;
      } catch (err) {
        console.log(err);
      }
    }
  },
  actions: {
    setLanguage({ commit }, languages) {
      try {
        if (typeof languages === 'string') {
          commit('SET_LANGUAGE', languages);
        } else {
          const language = supportedLanguages.find(sl =>
            languages.find(l =>
              l.split(new RegExp(sl, 'gi')).length - 1 > 0 ? sl : null
            )
          );
          commit('SET_LANGUAGE', language);
        }
      } catch (err) {
        console.log('here is an error language.js');
      }
    }
  }
};
