const localStorageSystemUserKey = 'lsSystemUser';
import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import language from "./modules/language";
const localStorageManager = require('./localStoreManager.store');

function updatelocalStorage(state) {
    localStorageManager.writeToStorage(localStorageSystemUserKey, JSON.stringify(state));
}

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        language,
    },

    strict: true,

    plugins: [createPersistedState()],
    state: {
        clinic: null,
        id: null,
        user: null,
        member: null,
        clinicLocationId: null,
        userName: null,
        isDirty: null
    },
    mutations: {
        setClinic(state, clinic) {
            state.clinic = clinic;
            state.isUserLoggedIn = !!clinic;
            updatelocalStorage(state);
        },
        setUser(state, user) {
            state.user = user;
            state.isUserLoggedIn = !!user;
            updatelocalStorage(state);
        },
        setAcceptedCookie(state, acceptedCookie) {
            state.acceptedCookie = acceptedCookie;
            updatelocalStorage(state);
        },
        setIsDirty(state, isDirty){
            state.isDirty = isDirty;
            updatelocalStorage(state);
        }
    },
    actions: {
        setClinic({commit}, clinic) {
            commit("setClinic", clinic);
        },
        setUser({ commit }, user) {
            commit("setUser", user);
        },
        setAcceptedCookie({ commit }, acceptedCookie) {
            commit("setAcceptedCookie", acceptedCookie);
        },
        setIsDirty({ commit }, isDirty) {
            commit("setIsDirty", isDirty);
        },
    },
    getters: {
        getUser: (state) => {
            return state.user;
        },
    },
});

export default store;
