import Vue from "vue";
import VueRouter from "vue-router";
// import SurveyEditor from '@/components/SurveyEditor'

import routes from "./routes";
import store from "../store";
import { i18n } from "../languages/i18n.js";

Vue.use(VueRouter);

const Router = new VueRouter({
    mode: "history",
    routes,
});

Router.beforeEach((to, from, next) => {
    try {
        if (store.state.language.language && store.state.language.language !== i18n.locale) {
            i18n.locale = store.state.language.language;
            next();
        } else if (!store.state.language.language) {
            store.dispatch("language/setLanguage", navigator.languages).then(() => {
                i18n.locale = store.state.language.language;
                next();
            });
        }
        if(from.name ==="PatientSurveys"){
            if(store.state.isDirty){
                const result = window.confirm(i18n.t('leaveWithoutSaving'));
                if (result) {
                    // User pressed "OK" --> discard changes
                    next();
                } else {
                    // Stay on page
                    this.$store.dispatch("setIsDirty", false);
                    next(false);
                }
            }
            else {
                next();
            }
        } else {
            next();
        }
    } catch (err) {
        console.log(err);
    }
});

export default Router;
