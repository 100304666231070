import Vue from 'vue'
import App from './App.vue'
import VueMq from 'vue-mq'
import { sync } from 'vuex-router-sync';
import store from './store';
import Vuetify from 'vuetify';
import router from './router';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import '@mdi/font/css/materialdesignicons.css'
import { i18n } from './languages/i18n.js';
import * as Sentry from "@sentry/vue";
Vue.config.productionTip = false


Vue.use(Vuetify);
Vue.use(VueMq, {
  breakpoints: { // default breakpoints - customize this
    mobile: 960, // = vue "sm"
    tablet: 1281,
    desktop: Infinity,
  },
  defaultBreakpoint: 'md' // customize this for SSR
})
Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DNS,
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [process.env.VUE_APP_SENTRY_TARGET_URL],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    environment: process.env.VITE_APP_ENVIRONMENT,
});

sync(store, router);

new Vue({
  vuetify: new Vuetify(),
  i18n,
  components: { App },
  router,
  store,
  render: h => h(App),
}).$mount('#app')
