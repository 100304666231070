export default {
    language_picker_helper: "LAN",
    languages: {
        english: "Inglese",
        german: "Tedesco",
        dutch: "Olandese",
        italian: "Italiano",
    },
    language: "Lingua",
    languageForCorrespondence: "Lingua per la corrispondenza",
    // PatientRegister.vue
    linkExpiredText: "Link di registrazione scaduto! Si prega di contattare la clinica",
    selfRegister: "Autoregistrazione",
    confirmEmailText: "Conferma il tuo indirizzo email.",
    required: "Necessario",
    invalidPassword: "La password deve contenere almeno una cifra.\n" +
        "La password deve contenere almeno una lettera minuscola.\n" +
        "La password deve contenere almeno una lettera maiuscola.\n" +
        "La password deve contenere almeno un carattere speciale dall'insieme fornito.\n" +
        "La lunghezza della password deve essere compresa tra 8 e 32 caratteri.",
    passwordNotMatch: "Le password non coincidono",
    usernameAlreadyExists: "Il nome utente esiste già",
    invalidEmail: "E-mail non valida.",
    DSGVOTitle: 'Informativa sulla privacy',
    DSGVOText: ' <p>Il seguente fornitore terzo fornisce l\'infrastruttura tecnica per il MedITEX Survey ed elabora i dati per nostro conto: ' +
        '          <br/></br>' +
        '          CRITEX GmbH</br>' +
        '          Stobäusplatz 4</br>' +
        '          93047 Regensburg</br>' +
        '          Germany' +
        '          <br/><br/>' +
        '          Il MedITEX Survey è stato sviluppato in conformità con il Regolamento generale europeo sulla protezione dei dati (GDPR) e viene gestito costantemente in conformità con esso. Si prega di notare che la politica sulla privacy della clinica responsabile è sempre stata rispettata.' +
        '          Ai sensi del Regolamento Generale sulla Protezione dei Dati dell\'UE, la informiamo sul trattamento dei suoi dati personali, in particolare i dati sanitari, nel contesto dell\'utilizzo del modulo di anamnesi online.' +
        '        </p>' +
        '        <h2>1. RESPONSABILITA PER IL TRATTAMENTO DEI DATI </h2>' +
        '        <p>' +
        '          <br/>' +
        '          Il responsabile per il trattamento dei dati è:' +
        '          <br/>' +
        '           { ClinicName } di seguito denominata "Clinica".' +
        '          <br/>' +
        '          È possibile contattare il/i responsabile/i del trattamento dei dati chiedendo direttamente il recapito alla clinica.' +
        '        </p>' +
        '        <h2>2. FINALITÀ E BASE GIURIDICA DEL TRATTAMENTO DEI DATI</h2>' +
        '        <p>' +
        '          <br/>' +
        '          I suoi dati personali vengono raccolti tramite il modulo anamnestico online al fine di permettere il trattamento previsto dall\'accordo stipulato tra lei e il suo medico. A tale scopo, la clinica richiede i suoi dati anagrafici (quali, ad esempio: nome, età, luogo di residenza) e i suoi dati sanitari, che potrà inserire in questo modulo di anamnesi online.' +
        '          <br/>' +
        '          La raccolta dati è un prerequisito per il suo trattamento. Se non vengono fornite le informazioni richieste, non potrà essere eseguito il corretto trattamento da parte del suo medico.  Qualora non desideri che i suoi dati personali vengano raccolti tramite il modulo anamnestico online, contattati direttamente il suo medico.  La base giuridica per il trattamento dei dati è l\'articolo 9, paragrafo 2, lettera h) Reg. UE 2016/679 (GDPR) in combinato disposto da quanto previsto dal D.Lgs. 196/2003 e successive modifiche apportate dal D.Lgs. 101/2018.' +
        '        </p>' +
        '        <h2>3. DESTINATARI DEI SUOI DATI</h2>' +
        '        <p>' +
        '          <br/>' +
        '          Itamedical Srl (Via del Commercio 56, 36100 Vicenza, info@itamedical.it), fornitore del software gestionale utilizzato dal reparto di PMA della Clinica, è anche responsabile per la fornitura di questo modulo anamnestico online. Il contenuto del modulo di anamnesi e il trattamento dei dati personali sono di esclusiva responsabilità dalla clinica che è anche il destinatario dei suoi dati.' +
        '          <br/>' +
        '          Itamedical Srl ha stipulato un contratto di gestione e manutenzione di questi dati con la clinica.' +
        '          <br/>' +
        '          Pertanto il personale di Itamedical potrà avere accesso ai suoi dati personali per adempiere agli obblighi legali e contrattuali con la clinica.' +
        '        </p>' +
        '        <h2>4. CONSERVAZIONE DEI SUOI DATI</h2>' +
        '        <p>' +
        '          <br/>' +
        '          I suoi dati personali saranno conservati solo per il tempo necessario alla clinica per raggiungere gli scopi sopra menzionati.  Dopo l\'eventuale risoluzione dell\'accordo di trattamento tra lei e la clinica, i suoi dati personali saranno conservati in base a quanto previsto dalla legislazione in vigore per gli episodi di cura che la riguardano.' +
        '        </p>' +
        '        <h2>5. I SUOI DIRITTI</h2>' +
        '        <p>' +
        '          <br/>' +
        '          Il trattamento dei suoi dati è regolato dalle disposizioni di legge in vigore.' +
        '          <br/>' +
        '          Lei ha il diritto di ottenere informazioni sui suoi dati personali e può richiedere che i dati errati vengano corretti.' +
        '          <br/>' +
        '          Inoltre, in determinate condizioni, ha il diritto di richiedere la cancellazione dei suoi dati, di limitarne l\'accesso al trattamento e il diritto di portabilità.' +
        '          <br/>' +
        '          Infine, ha il diritto di sporgere denuncia all\'autorità di garanzia per la protezione dei dati se ritiene che il trattamento dei suoi dati personali non sia conforme al GDPR.' +
        '          <br/>' +
        '          Gli uffici del Garante per la Protezione dei dati Personali sono raggiungibili a questo indirizzo: https://www.garanteprivacy.it/home/autorita/ufficio' +
        '        </p>' +
        '        <h2>6. ALTRO</h2>' +
        '        <p>' +
        '          <br/>' +
        '          Ulteriori disposizioni sulla protezione dei dati derivano dai contratti stipulati con la clinica.' +
        '          <br/>' +
        '          Se ha domande o desidera esercitare i suddetti diritti, non esiti a contattare la clinica.' +
        '        </p>' +
        '        <p>' +
        '          <br/>' +
        '          Facendo clic sul pulsante "Accetto", acconsente espressamente al trattamento dei suoi dati personali e alla trasmissione di tali dati alla clinica.' +
        '        </p>',

    userName: "Nome utente",
    lastName: "Cognome",
    firstName: "Nome",
    birthdate: "Data di nascita",
    password: "Password",
    repeatPassword: "Ripetere password",
    register: "registrati",
    createAccount: "Crea account",
    agb: "AGB",
    dsgvo_1: "Ho letto ",
    dsgvo_2: 'l\'informativa sulla privacy',
    dsgvo_3: ".",
    close: "Chiudi",
    dsgvoReminder: 'Accetta i nostri termini sulla privacy per continuare.',

    registrationConfirmation: 'Conferma registrazione',
    registrationConfirmed: 'Registrazione confermata.',
    registrationConfirmationExpired: 'Link di conferma della registrazione scaduto.',
    login: 'Accesso',
    backToLoginFromRegister: 'Già registrato? Accedi',
    email: 'E-mail',
    cancel: 'Cancella',
    create: 'Crea',
    agree: 'Accetto',
    disagree: 'Rifiuto',

    patients: 'Pazienti',
    openSurveys: 'Questionari aperti',
    chooseSurvey: 'Selezionare il questionario',
    noDataAvailable: 'Non è stato possibile trovare alcun questionario. Si noti che un questionario può essere completato solo una volta.',
    deletePartner: 'Elimina parter',
    disconnectPartner: 'Disassocia partner',
    addPartner: 'Aggiungi partner',
    name: 'Nome',
    forename: 'Cognome',
    surveyName: 'Nome Questionario',
    status: 'Stato',
    actions: 'Azioni',
    deletePartnerTitle: 'Elimina parter',
    deletePartnerText: 'Vuoi davvero eliminare il tuo partner?',
    disconnectPartnerTitle: 'Disassocia Partner',
    disconnectPartnerText: 'Vuoi davvero disassociare il tuo partner? La modifica non è reversibile!',
    surveySentToClinic: 'Questionario inviato alla clinica',
    surveySentBackToEdit: 'Questionario inviato indietro per essere modificato',
    save: 'Salva',
    sendSurvey: 'Invia questionario alla clinica',
    surveys: 'Questionari',
    deleteSurveyTitle: 'Elimina questionario',
    deleteSurveyText: 'Vuoi davvero eliminare questo questionario? La cancellazione non è reversibile!',
    savedCurrentState: 'Salva lo stato attuale',
    greeting: 'Ciao',
    logout: 'Disconnettiti',
    addSurvey: "Aggiungi questionario",
    help: "Aiuto",
    helpText: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
    patientTutorial: "<div><p>1. Sul lato sinistro dello schermo hai due opzioni:\"Dati personali\" e \"Storia medica\". Seleziona <strong>\"Dati personali\"</strong> per compilare i dati demografici o seleziona <strong>\"Storia medica\"</strong> per inserire la tua storia medica.</p>\r<p>2. Il tuo nome è stato già aggiunto. Ora seleziona <strong>\"Aggiungi questionario\"</strong>. </p>\r<p>3. Verrà visualizata la tua <strong>Lista questionari</strong>. Seleziona un nuovo questionario facendo clic su <strong>\"aggiungi questionario\"</strong>. Seleziona una delle possibili opzioni. </p>\r<p>4. Compila il questionario.</p>\r<p>5. Se vuoi compilare il questionario in un secondo momento, clicca sul pulsante <strong>\"salva\"</strong>, posizionato sul lato destro.</p>\r<p>6. Una volta completato il questionario, è possibile selezionare il pulsante <strong>\"invia questionario alla clinica\"</strong>, anch'esso posizionato sul lato destro. Se la clinica offre un questionario sui dati anagrafici, è necessario inviare prima questo e poi l'anamnesi.</p>\r<p>7. Per accedere di nuovo ai tuoi questionari, selezionare \"Dati personali\" o \"Storia medica personali\" nella parte sinistra dello schermo. Qui puoi vedere tutti i questionari che hai compilato.</p>\r<p>8. E'' possibile aggiungere un partner selezonando l'opzione \"aggiungi partner\". Puoi trovare questa funzione sul lato destro sotto il tuo profilo. Il tuo partner può ora ripetere lo stesso procedimento.</p>\r<p></p></div>",
    imprint: 'imprint',
    typeMessage: "Digita un messaggio",
    patientConversation: "Conversazione paziente",
    resendMail: "Invia nuovamente l'e-mail",

    noResultsFound: "nessun risultato trovato",
    surveysPerSite: "Questionari per pagina",

    //patientProfile
    myAccount: "Il mio profilo",
    editAccount: "Modifica profilo",
    myPartner: "Il mio partner",
    editPartner: "Modifica partner",
    changePassword: "Cambia password",
    currentPassword: "Password attuale",
    newPassword: "nuova password",
    repeatNewPassword: "ripetere nuova password",

    //decisionTree
    decisionTreeStartText: "Si prega di compilare questo questionario in buona coscienza. Se la nostra clinica offre il servizio che stai cercando, alla fine è presente un'opzione per richiedere un appuntamento.",

    phoneNumber: "Numero di telefono",
    appointmentRequest: "Richiesta di appuntamento",
    requestAppointment: 'richiedi appuntamento',
    back: 'indietro',
    next: 'prossimo',
    start: 'inizia',
    surveyNext: "Successivo",
    surveyPrev: "Precedente",

    phoneNumberRule: "Numero di telefono non valido! Usa solo 0-9 e -",
    nothingSelectedError: "Niente di selezionato!",
    appointmentRequestedText: "Grazie per aver inserito i tuoi contatti. Dovresti ricevere un'e-mail di conferma. Ti contatteremo a breve.",

    //passwordReset
    forgotPassword: "Password dimenticata?",
    forgotPasswordText: '<div>Inserisci il tuo nome utente e il codice di recupero. Riceverai un nuovo codice di recupero al prossimo login riuscito.</br></br><strong>Se hai creato il tuo account prima di luglio 2024, non possiedi un codice di recupero. Per motivi di sicurezza, non possiamo più offrire il reset della password via email. Contatta la tua clinica e/o crea un nuovo account utente.</strong></div>',
    sentResetMail: "Ti è stata inviata una mail. Fare clic sul collegamento nell'e-mail per reimpostare la password",
    sendEmail: "Invia e-mail",
    backToLogin: "Torna al login",
    resetPasswordText: "Inserisci una nuova password",
    linkExpired: "Questo collegamento è scaduto",
    resetPassword: "resetta password",
    logoutAfterPasswordChange: "Sarai disconnesso dopo aver cambiato la tua password.",
    recoveryCode:"chiave di recupero",
    confirm: "Conferma",

    //snackbar
    unexpectedError: 'Si è verificato un errore imprevisto',
    errorCreatingPartner: "Non è stato possibile creare il partner",
    errorConfirmingRegistration: "Non è stato possibile confermare la registrazione",
    invalidLogin: "Nome utente o password errati!",
    errorDeletingPartner: "Non è stato possibile eliminare il partner",
    errorDisconnectingPartner: "Non è stato possibile disassociare il partner",
    errorFinishingRegistration: "Non è stato possibile completare la registrazione",
    errorRegisteringPatient: "Non è stato possibile registrare il paziente",
    mailResent: "Invia nuovamente l'e-mail",
    errorResendingMail: "Non è stato possibile inviare nuovamente l'e-mail",
    errorDeletingSurvey: "Non è stato possibile eliminare il questionario",
    surveyResultSaved: "Questionario salvato",
    errorSavingSurveyResult: "Non è stato possibile salvare il questionario",
    surveyResultSendToClinic: "Risultati del questionario inviati alla clinica",
    errorGettingPatientInformation: "Non è stato possibile ottenere informazioni sul paziente",
    patientUpdated: "Paziente modificato",
    errorUpdatingPatient: "Non è stato possibile modificare il paziente",
    partnerUpdated: "Partner modificato",
    errorUpdatingPartner: "Non è stato possibile modificare il partner",
    passwordChanged: "Password cambiata",
    errorChangingPassword: "Non è stato possibile cambiare la password",
    errorSendingResetMail: "Non è stato possibile inviare l'e-mail",
    errorResettingPassword: "Non è stato possibile resattare la password",
    messageDeleted: "Messaggio eliminato",
    errorEstablishingRecoveryKey: "Si è verificato un errore durante la creazione della chiave di recupero.",
    successEstablishingRecoveryKey: "RChiave di recupero creata.",

    //cookies
    cookiesText: "<div><p>I cookie memorizzati su questo sito Web sono solo per il processo di accesso. Il consenso è  <strong>necessario</strong> per l'utilizzo.</p></div>",
    cookieLabel: "Login",
    result: "Risultato",
    openDemographics: "Dati anagrafici aperti",
    demographicsNotAvailable: "Non esiste ancora un questionario anagrafico per la vostra clinica.",
    information: "Informazioni",
    invalidEMail: "E-mail non valida.",
    gender: "Genere",
    female: "femminile",
    male: "maschile",
    diverse: "diverso",
    dateOfCreation: "Data di creazione",
    surveyInProgress: "In corso",
    notPossible: "Non è possibile",
    fillOut: "Salva + Compila i dati",
    warningDemographicsFirst: "Si prega di inviare prima il questionario dei dati anagrafici alla clinica.",
    leaveWithoutSaving: "L'uscita da questa pagina comporterà la perdita di tutte le modifiche non salvate. Sei sicuro di voler procedere?",
    personalData:"Dati personali",
    medicalHistory:"Storia medica",
    surveyNotActivated: "La funzione di storia medica non è attiva.",

    recoveryOne: "Si prega di creare un metodo di recupero per garantire sempre l'accesso al proprio account. Questo può consentire di accedere al proprio account in caso di password persa o dimenticata.",
    recoveryTwo: "Fare clic sul pulsante per configurare un metodo di recupero.",
    recoveryThree: "Questa è la tua chiave di recupero. Ti preghiamo di copiarla e conservarla in un luogo sicuro.",
    recoveryFour: "Inserisci la password per confermare la ricezione della chiave di recupero.",
    recoveryMethod: "Crea metodo di recupero",
    recovery: "Recupero"
};
