import store from "../store/index";

function BeforeEnterCheck(to, from, next) {

    if ((store.state.user === null)) {
        next('/Login');
    } else {
        next()
    }
}

export default [
    {
        name: "ViewIndex",
        path: "/views",
        component: () => import("../views"),
        children: [
            {
                path: "/SurveysOverview",
                name: "SurveysOverview",
                component: () => import("../views/datatables/SurveysOverview"),
                beforeEnter: (to, from, next) => {
                    BeforeEnterCheck(to, from, next)
                },

            },
            {
                path: "/PatientsOverview",
                name: "PatientsOverview",
                component: () => import("../views/datatables/PatientsOverview"),
                beforeEnter: (to, from, next) => {
                    BeforeEnterCheck(to, from, next)
                },
            },
            {
                path: "/PatientSurveys",
                name: "PatientSurveys",
                component: () => import("../views/PatientSurveys"),
                beforeEnter: (to, from, next) => {
                    BeforeEnterCheck(to, from, next)
                },
            },
            {
                path: "/PatientProfile",
                name: "PatientProfile",
                component: () => import("../views/PatientProfile"),
                beforeEnter: (to, from, next) => {
                    BeforeEnterCheck(to, from, next)
                },
            }
        ]
    },
    {
        path: "/",
        name: "root",
        component: () => import("../pages/PatientLogin"),
    },
    {
        path: "/ConfirmRegistration",
        name: "ConfirmRegistration",
        component: () => import("../pages/ConfirmRegistration"),
    },
    {
        path: "/Login",
        name: "PatientLogin",
        component: () => import("../pages/PatientLogin"),

    },

    {
        path: "/ResetPassword",
        name: "ResetPassword",
        component: () => import("../pages/ResetPassword"),
    },

    {
        path: "/PatientRegister",
        name: "PatientRegister",
        component: () => import("../pages/PatientRegister"),
    },

    {
        path: "/DecisionTree",
        name: "DecisionTree",
        component: () => import("../pages/DecisionTree"),
    },

    {
        path: "/404",
        name: "notFound",
        component: () => import("../pages/FourOFour"),
    }

]